import React from "react"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

import "./subscribe.css"

const SubscribeTitle = styled.div`
  text-align: left;
  font: Black 1.2em Fira Sans;
  letter-spacing: 0.75px;
  color: #1e4778;
  padding-top: 10px;
`

const SubscribeSubtitle = styled.div`
  text-align: left;
  font: Bold 0.8em Fira Sans;
  letter-spacing: 0;
  color: #454d62;
`

function createMarkup() {
  return {
    __html: `
    <!-- Begin Mailchimp Signup Form -->
    <div id="mc_embed_signup" class="mailchip_cont">
        <form action="https://tigase.us4.list-manage.com/subscribe/post?u=e715c173fe31a155cd9c5d7e5&amp;id=8cc264335d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll" class="mailchip_cont">

                <div class="mailchimp_sub">
                    <input placeholder="Email" type="email" value="" name="EMAIL" class="mailchimp_sub_input" id="mce-EMAIL">
                </div>
                <div class="mailchimp_sub">
                    <input placeholder="Name" type="text" value="" name="FNAME" class="mailchimp_sub_input" id="mce-FNAME">
                </div>
                <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display:none"></div>
                    <div class="response" id="mce-success-response" style="display:none"></div>
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input type="text" name="b_e715c173fe31a155cd9c5d7e5_8cc264335d" tabindex="-1" value="">
                </div>
                <div class="clear mailchimp_sub">
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="mailchimp_send">
                </div>
            </div>
        </form>
    </div>
    <!--End mc_embed_signup-->
    `,
  }
}

function SubscriptionZoho() {
  return (
    <div style={{ padding: "1em" }} dangerouslySetInnerHTML={createMarkup()} />
  )
}

const Subscribe = () => {
  return (
    <Grid container justify="center" style={{ margin: 20 }}>
      <div style={{ display: "block", padding: "1em" }}>
        <SubscribeTitle>Subscribe to TIGASE</SubscribeTitle>
        <SubscribeSubtitle>
          Stay up to date with news and updates in our software
        </SubscribeSubtitle>
      </div>
      <SubscriptionZoho />
    </Grid>
  )
}

export default Subscribe
