import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"

class PopupDialog extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          style={this.props.style}
          fullWidth={this.props.fullWidth}
        >
          <DialogActions
            style={{
              position: this.props.absoluteClose ? "relative " : "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Button onClick={this.props.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
          <DialogContent>{this.props.children}</DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default PopupDialog
