import React from "react"
import styled from "styled-components"
import { DropdownSection, Heading } from "./Components"

import { Link } from "gatsby"

const ProductsDropdownEl = styled.div`
  width: 25rem;
`

const Flex = styled.div`
  display: block;
  margin: 20px;
`

const LinkItem = styled.div``

const ProductsDropdown = () => {
  return (
    <ProductsDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <Link to="/tigase-instant-communication">
          <LinkItem>Instant Communication And Messaging</LinkItem>
        </Link>
      </DropdownSection>
      <DropdownSection>
        <Heading>XMPP Software</Heading>
        <div>
          <Flex>
            <Link to="/xmpp-server">
              <LinkItem>XMPP Server</LinkItem>
            </Link>
          </Flex>
        </div>
        <div>
          <Flex>
            <Link to="/xmpp-clients">
              <LinkItem>XMPP Clients</LinkItem>
            </Link>
          </Flex>
        </div>
        <div>
          <Flex>
            <Link to="/xmpp-libraries">
              <LinkItem>XMPP Libraries</LinkItem>
            </Link>
          </Flex>
        </div>
      </DropdownSection>
      {/*
      <DropdownSection>
        <div>
          <Heading>IoT Software</Heading>
          <Flex>
            <Link to="/xmpp-server">
              <LinkItem>IoT Hub</LinkItem>
            </Link>
          </Flex>
          <Flex>
            <Link to="/xmpp-clients">
              <LinkItem>IoT Clients</LinkItem>
            </Link>
          </Flex>
          <Flex>
            <Link to="/xmpp-libraries">
              <LinkItem>IoT Framework</LinkItem>
            </Link>
          </Flex>
          <Flex>
            <Link to="/xmpp-libraries">
              <LinkItem>IoT Cloud</LinkItem>
            </Link>
          </Flex>
        </div>
      </DropdownSection>
       */}
    </ProductsDropdownEl>
  )
}

export default ProductsDropdown
