import React from "react"
import styled from "styled-components"
import { DropdownSection } from "./Components"

import { Link } from "gatsby"

const PricingEl = styled.div`
  width: 25rem;
`

const Flex = styled.div`
  display: flex;
  margin: 10px;
  > div:first-of-type {
    margin-right: 48px;
  }
`

const LinkItem = styled.div``

const Pricing = () => {
  return (
    <PricingEl>
      <DropdownSection data-first-dropdown-section>
        <div>
          <Flex>
            <Link to="/pricing-xmpp">
              <LinkItem>Software License</LinkItem>
            </Link>
          </Flex>
        </div>
      </DropdownSection>
      <DropdownSection>
        <div>
          <Flex>
            <Link to="/pricing-technical">
              <LinkItem>Technical Support</LinkItem>
            </Link>
          </Flex>
        </div>
      </DropdownSection>
    </PricingEl>
  )
}

export default Pricing
