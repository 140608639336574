import React from "react"
import { StaticQuery, graphql } from "gatsby"

import PricingDropdown from "./DropdownContents/PricingDropdown"
import ProductsDropdown from "./DropdownContents/ProductsDropdown"
import BlogDropdown from "./DropdownContents/BlogDropdown"
import ContactDropdown from "./DropdownContents/ContactDropdown"
import AnimatedNavbar from "./AnimatedNavbar"

const AnimatedNavbarContainer = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fields: { sourceName: { eq: "blog" } } }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                  date(formatString: "MMMM DD, YYYY")
                }
              }
            }
          }
        }
      `}
      render={data => {
        const navbarConfig = [
          {
            title: "Products",
            link: "tigase-instant-communication",
            dropdown: ProductsDropdown,
          },
          { title: "Pricing", link: "pricing-xmpp", dropdown: PricingDropdown },
          {
            title: "Blog",
            link: data.allMarkdownRemark.edges[0].node.fields.slug,
            dropdown: BlogDropdown,
          },
          { title: "Contact", link: "contact", dropdown: ContactDropdown },
        ]

        return (
          <AnimatedNavbar
            navbarConfig={navbarConfig}
            duration={props.duration}
          />
        )
      }}
    />
  )
}

export default AnimatedNavbarContainer
