import React from "react"
import Subscribe from "../subscribe/subscribe"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Facebook from "../../../static/social/facebook.svg"
import Twitter from "../../../static/social/twitter.svg"

const FooterCreatedBy = styled.div`
  font-family: Arial;
  font-weight: 900;
  font-size: 8px;
  line-height: 28px;
  text-align: left;
  color: white;
  margin: 10px;
`

const ImgSocial = styled.img`
  margin: 10px;
  color: "white";
`

const Footer = () => {
  return (
    <React.Fragment>
      <Grid container style={{ backgroundColor: "#f1f9ff", marginTop: 80 }}>
        <Subscribe />
      </Grid>
      <Grid
        container
        justify={"space-between"}
        alignItems="center"
        style={{ backgroundColor: "#1a1a1a" }}
      >
        <Grid item>
          <FooterCreatedBy>© TIGASE 2019 created by BoringOwl</FooterCreatedBy>
        </Grid>
        <Grid item>
          <a href="https://www.facebook.com/tigase">
            <ImgSocial src={Facebook} />
          </a>
          <a href="https://twitter.com/tigase">
            <ImgSocial src={Twitter} />
          </a>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Footer
