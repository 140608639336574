import React from "react"
import Menu from "../components/menu/menu"
import Footer from "../components/footer/footer"
import ConsentSnackBar from "../components/consent/consent"
import Cookies from "universal-cookie"

class Layout extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll)
  }
  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200,
      headerEl = document.getElementById("js-header")

    if (distanceY > shrinkOn) {
      headerEl.classList.add("smaller")
    } else {
      headerEl.classList.remove("smaller")
    }
  }

  render() {
    const { children } = this.props
    const cookies = new Cookies()

    return (
      <React.Fragment>
        <header id="js-header" style={{ position: "relative" }}>
          <Menu />
        </header>
        <main>{children}</main>
        <footer>
          <Footer />
        </footer>
        <style jsx>
          {`
            @media screen and (min-width: 600px) {
              header {
                position: sticky;
                top: 0;
                left: 0;
                font-size: 1em;
                z-index: 999;
                background-color: white;
                transition: height 1s;
                transition: font-size 0.3s;
              }
              header.smaller {
                font-size: 0.9em;
              }
              header.img {
                height: 90%;
                width: 90%;
              }
            }
          `}
        </style>
        {cookies.get("tigasewebsiteconsent") !== "accepted" && (
          <ConsentSnackBar />
        )}
      </React.Fragment>
    )
  }
}

export default Layout
