import React, { Component } from "react"
import AnimatedNavbar from "./AnimatedNavbarContainer"
import "./index.css"

class MenuStripe extends Component {
  state = { duration: 300 }

  onChange = data => {
    this.setState(data)
  }

  render() {
    return <AnimatedNavbar duration={this.state.duration} />
  }
}

export default MenuStripe
