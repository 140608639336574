import React from "react"
import styled from "styled-components"
import { DropdownSection, Heading } from "./Components"

import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

const BlogDropdownEl = styled.div`
  width: 25rem;
`

const Flex = styled.div`
  display: block;
  margin: 20px;
  font-size: 0.8em;
`

const BlogDropdown = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { fields: { sourceName: { eq: "blog" } } }
          limit: 5
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={data => (
      <BlogDropdownEl>
        <DropdownSection data-first-dropdown-section>
          <Heading>Recent articles</Heading>
          {data.allMarkdownRemark.edges &&
            data.allMarkdownRemark.edges.map(item => {
              return (
                <div key={item.node.frontmatter.title}>
                  <Flex>
                    <Link to={item.node.fields.slug}>
                      {item.node.frontmatter.date}:{" "}
                      {item.node.frontmatter.title}
                    </Link>
                  </Flex>
                </div>
              )
            })}
        </DropdownSection>
        <DropdownSection>
          <Link to="/blog">All articles</Link>
        </DropdownSection>
      </BlogDropdownEl>
    )}
  />
)

export default BlogDropdown
