import React from "react"
import styled from "styled-components"
import { TigaseLogoMobile } from "../../components/logo"
import Container from "@material-ui/core/Container"
import MenuStripe from "./menustripe/index"
import { AppBar } from "@material-ui/core"
import Toolbar from "@material-ui/core/Toolbar"
import ElevationScroll from "./menumobile/elevation-scroll"
import MenuMobile from "./menumobile/menu-mobile"
import { Link } from "gatsby"

const DesktopContainer = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`

const MobileContainer = styled.div`
  @media (min-width: 700px) {
    display: none;
  }
`

const Menu = () => {
  return (
    <React.Fragment>
      <DesktopContainer>
        <ElevationScroll>
          <AppBar color="inherit" position="fixed">
            <Container maxWidth="lg">
              <Toolbar variant="dense">
                <Link to="/">
                  {" "}
                  <TigaseLogoMobile />
                </Link>
                <MenuStripe />
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
      </DesktopContainer>
      <MobileContainer>
        <ElevationScroll>
          <AppBar color="inherit" position="fixed">
            <Toolbar
              variant="dense"
              style={{ justifyContent: "space-between" }}
            >
              <Link to="/">
                {" "}
                <TigaseLogoMobile />
              </Link>
              <MenuMobile />
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </MobileContainer>
    </React.Fragment>
  )
}

export default Menu
