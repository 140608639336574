import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import Button from "@material-ui/core/Button"
import Cookies from "universal-cookie"

const ConsentStatus = () => {
  const [open, setOpen] = React.useState(true)

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return
    }
    const cookies = new Cookies()
    cookies.set("tigasewebsiteconsent", "accepted", { path: "/" })
    setOpen(false)
  }

  const consentText =
    "By continuing to use our website, you acknowledge the use of cookies."

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={60000}
      onClose={handleClose}
    >
      <div
        style={{
          color: "white",
          fontSize: 12,
          padding: 7,
          borderRadius: 15,
          display: "flex",
          backgroundColor: "#1C4677",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {consentText}
        </div>
        <Button onClick={handleClose} color={"inherit"}>
          OK
        </Button>
      </div>
    </Snackbar>
  )
}

export default ConsentStatus
