import React from "react"
import styled from "styled-components"
import { DropdownSection } from "./Components"
import { Link } from "gatsby"

const ContactDropdownEl = styled.div`
  width: 25rem;
`

const LinkItem = styled.div``

const ContactDropdown = () => {
  return (
    <ContactDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <Link to="/contact">
          <LinkItem> Company Information</LinkItem>
        </Link>
      </DropdownSection>
      <DropdownSection>
        <Link to="contact-sales">
          <LinkItem> Sales Questions</LinkItem>
        </Link>
      </DropdownSection>
      <DropdownSection>
        <Link to="technical-support">
          <LinkItem> Support Requests</LinkItem>
        </Link>
      </DropdownSection>
    </ContactDropdownEl>
  )
}

export default ContactDropdown
