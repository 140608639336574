import React from "react"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import PopupDialog from "../../popup/popup-dialog"
import { withStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ContactSupport from "@material-ui/icons/ContactSupport"
import News from "../../../../static/menu/news.svg"
import Products from "../../../../static/menu/products.svg"
import Pricing from "../../../../static/menu/pricing.svg"
import Link from "gatsby-link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Collapse from "@material-ui/core/Collapse"

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

const menuIconStyle = {
  width: 20,
  height: 20,
  color: "rgba(0, 0, 0, 0.54)",
}

const menuMobileItems = [
  {
    name: "Products",
    icon: <img src={Products} style={menuIconStyle} />,
    link: "/tigase-instant-communication",
    subsection: [
      {
        item: "Instant Communication And Messaging",
        link: "tigase-instant-communication",
      },
      {
        item: "XMPP Server",
        link: "xmpp-server",
      },
      {
        item: "XMPP Clients",
        link: "xmpp-clients",
      },
      {
        item: "XMPP Libraries",
        link: "xmpp-libraries",
      },
    ],
  },
  {
    name: "Pricing",
    icon: <img src={Pricing} style={menuIconStyle} />,
    link: "/pricing-xmpp",
    subsection: [
      {
        item: "Software License",
        link: "pricing-xmpp",
      },
      {
        item: "Technical Support",
        link: "pricing-technical",
      },
    ],
  },
  {
    name: "Blog",
    icon: <img src={News} style={menuIconStyle} />,
    link: "/blog",
    subsection: [
      {
        item: "Latest Articles",
        link: "blog",
      },
    ],
  },
  {
    name: "Contact",
    icon: <ContactSupport fontSize="small" />,
    link: "/contact",
    subsection: [
      {
        item: "Company Information",
        link: "contact",
      },
      {
        item: "Sales Questions",
        link: "contact-sales",
      },
      {
        item: "Support Request",
        link: "technical-support",
      },
    ],
  },
]

const CollapseItem = props => {
  return (
    <Collapse in={props.open} timeout="auto" unmountOnExit>
      {props.subsection.map(item => {
        return (
          <Link to={item.link} key={item}>
            <List component="div" disablePadding>
              <ListItem button dense>
                <ListItemText primary={item.item} inset />
              </ListItem>
            </List>
          </Link>
        )
      })}
    </Collapse>
  )
}

const MenuMobileItem = props => {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <>
      <StyledMenuItem onClick={handleClick}>
        <ListItemIcon>{props.item.icon}</ListItemIcon>
        <ListItemText primary={props.item.name} />
      </StyledMenuItem>
      <CollapseItem open={open} subsection={props.item.subsection} />
    </>
  )
}

const MenuMobileItems = () => {
  return (
    <>
      {menuMobileItems.map(item => {
        return <MenuMobileItem item={item} key={item} />
      })}
    </>
  )
}

const MenuMobile = () => {
  const [open, setOpen] = React.useState(false)

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <MenuIcon style={{ color: "#1e4778" }} />
      </IconButton>
      <PopupDialog
        absoluteClose
        fullWidth
        open={open}
        handleClose={handleClose}
        style={{ top: 0, left: 0, position: "absolute" }}
      >
        <MenuMobileItems />
      </PopupDialog>
    </div>
  )
}

export default MenuMobile
